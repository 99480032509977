
import {defineComponent, ref} from 'vue'
import ModalWrapper from "@/components/utils/modal/ModalWrapper.vue";

export default defineComponent({
  name: "AreYouSureModal",
  components: {ModalWrapper},
  emits: {
    onAction: (action: unknown) => typeof action === "boolean"
  },
  setup(_, {emit}) {
    const isOpen = ref(true)

    const currentAction = ref(false)
    const onConfirm = () => {
      currentAction.value = true
      isOpen.value = false
    }

    const onCancel = () => {
      currentAction.value = false
      isOpen.value = false
    }

    const onAction = () => emit('onAction', currentAction.value)
    return {isOpen, onAction, onConfirm, onCancel}
  }
})
