
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  setup() {
    return {}
  }
})
