
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    small: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    color: {
      type: String,
      default: "green",
    },
  },
  setup(props) {
    const colorClasses = computed(() => {
      if (props.color === "green")
        return ["bg-green-500", "text-white", "hover:bg-green-600"];
      else if (props.color === "lightgreen")
        return ["bg-green-600","bg-opacity-10", "text-green-600", "hover:text-green-700", "hover:bg-opacity-20"];
      return [];
    });
    const dynamicClasses = computed(() => {
      const classes = [];
      classes.push(
        props.small ? ["px-6", "py-2"] : ["px-4", "py-2.5", "font-normal"]
      );
      classes.push(
        props.disabled
          ? ["bg-gray-200", "text-gray-600", "cursor-not-allowed"]
          : [colorClasses.value, 'cursor-pointer']
      );
      return classes;
    });
    return { dynamicClasses };
  },
});
