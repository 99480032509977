
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseTableEditDeleteButtons",
  emits: {
    onEdit: () => true,
    onDelete: () => true,
  },
  props: {
    showEdit: {
      type: Boolean,
      default: () => true,
    },
    showDelete: {
      type: Boolean,
      default: () => true,
    },
  },
  setup(_, { emit }) {
    const onEditClick = () => emit("onEdit");
    const onDeleteClick = () => emit("onDelete");
    return { onEditClick, onDeleteClick };
  },
});
